import React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import PortfolioSection from "../components/sections/PortfolioSection";
import Footer from "../components/sections/Footer";

const indexPage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Portfolio"} description={``} />
    <Header />
    <PortfolioSection
      title={"Our Portfolio"}
      description={``}
      issuesId={"9048,9046,9045,8833,8806,17889,18111,17890,17888"}
      sourceChangedTitle={{ 9045: "Download now" }}
      sourceChangedTitle={{ 8833: "Open Regulation" }}
      page
    />
    <Footer />
  </div>
);

export default indexPage;
